.qr-modal {
  position: relative;
  margin-top: 50px;
  .close-button {
    position: absolute;
    top: -10px;
    right: -20px;
    width: 30px;
    height: 30px;
    background-color: #ff4d4f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    svg {
      font-size: 18px;
      color: #fff;
    }

    &:hover {
      background-color: #d9363e;
    }
  }
  .rs-icon {
    display: none !important;
  }
  .rs-modal-content {
    padding: 0 !important;
    .rs-modal-header {
      padding: 15px;
      border-bottom: 1px solid #ddd;

      .qr-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .qr-title-container {
          display: flex;
          align-items: center;
          gap: 10px;

          .qr-icon {
            font-size: 20px;
            color: #2e3c7e;
          }

          .qr-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
          }
        }

        .download-button {
          display: flex;
          gap: 5px;
          align-items: center;
          background-color: green;
          border-color: #2e3c7e;
          color: #fff;
          padding: 5px 15px;
          font-size: 14px;
        }
      }
    }

    .rs-modal-body {
      display: flex;
      flex-direction: column;
      height: auto !important;
      max-height: 550px !important;
      margin-top: 0;
      padding-bottom: 0;
      .qr-sidebar {
        width: 25%;
        background-color: #f9f9f9;
        border-right: 1px solid #ddd;
        padding: 15px;

        .qr-sidebar-option {
          padding: 10px 15px;
          font-size: 14px;
          color: #333333;
          border-radius: 5px;
          cursor: pointer;
          margin-bottom: 10px;
          text-align: left;

          &:hover {
            background-color: #e0e0e0;
          }

          &.active {
            color: #120f0f;
          }
        }
      }

      .qr-card {
        width: 300px;
        border-top: 1.4rem solid #2e3c7e;
        border-bottom: 1.4rem solid #2e3c7e;
        border-left: 0.7rem solid #2e3c7e;
        border-right: 0.7rem solid #2e3c7e;
        border-radius: 36px;
        text-align: center;
        padding: 20px;
        margin: auto;
        margin: 30px auto;

        .logo-container {
          width: 60px;
          height: 60px;
          border-radius: 25%;
          border: 1px solid #191919;
          overflow: hidden;
          margin: auto;
          .logo-pic {
            width: 90%;
            height: 90%;
          }
        }

        .qr-primary-text {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 5px;
          line-height: 1.5;
        }

        .qr-secondary-text {
          font-size: 14px;
          color: #666666;
          margin-bottom: 15px;
          font-weight: 700;
          line-height: 1.5;
        }

        .qr-code-container {
          margin: 20px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .qr-link {
          display: inline-block;
          text-decoration: none;
          position: relative;
        }
        .qr-hover-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.7);
          padding: 5px 10px;
          border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s ease, visibility 0.3s ease;
        }

        .qr-link:hover .qr-hover-text {
          opacity: 1;
          visibility: visible;
        }

        .qr-footer {
          font-size: 12px;
          color: #666666;
          margin-top: 15px;

          .qr-footer-logos {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 10px;

            img {
              height: 40px;
            }
          }
        }
      }
      .not-qr-text {
        text-align: center;
        color: #ff0000;
        font-size: 16px;
        font-weight: 700;
        margin: 20px 20px;
      }
      .qr-share-text {
        margin: 20px 20px;
        text-align: center;
        font-size: 14px;
        color: #090909;
        font-weight: 500;
      }
    }
  }
}
