// @font-face {
// 	font-family: Montserrat;
// 	font-weight: 500;
// 	src: url(../fonts/ondc/Montserrat-Medium.ttf);
// }
// @font-face {
// 	font-family: Montserrat;
// 	font-weight: 700;
// 	src: url(../fonts/ondc/Montserrat-Bold.ttf);
// }
@font-face {
	font-family: Raleway;
	font-weight: 500;
	src: url(../../../assets/fonts/ondc/Raleway-Medium.ttf);
}
@font-face {
	font-family: Raleway;
	font-weight: 600;
	src: url(../../../assets/fonts/ondc/Raleway-SemiBold.ttf);
}
@font-face {
	font-family: Raleway;
	font-weight: 700;
	src: url(../../../assets/fonts/ondc/Raleway-Bold.ttf);
}
@font-face {
	font-family: Lato;
	font-weight: 400;
	src: url(../../../assets/fonts/ondc/Lato-Regular.ttf);
}
@font-face {
	font-family: Lato;
	font-weight: 600;
	src: url(../../../assets/fonts/ondc/Lato-Bold.ttf);
}
@font-face {
	font-family: "Open Sans";
	font-weight: 400;
	src: url(../../../assets/fonts/ondc/OpenSans-Regular.ttf);
}
@font-face {
	font-family: "Open Sans";
	font-weight: 600;
	// src: url(../fonts/ondc/OpenSans-SemiBold.ttf);
	src: url(../../../assets/fonts/ondc/OpenSans-SemiBold.ttf);
}
/* Common CSS starts*/
/* Common CSS ends*/
/* HEADER */
// header {
//   padding: 18px 80px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   position: sticky;
//   top: 0;
//   z-index: var(--z-index-header);
//   background-color: #fff;
// }

// header ul {
//   list-style-type: none;
//   margin: 0;
//   padding: 0;
//   display: flex;
//   align-items: center;
//   font-size: 14px;
// }

// header ul li {
//   display: inline-block;
// }
// header ul li a {
//   text-decoration: none;
//   color: var(--ondc-secondary-1);
//   font-family: Montserrat;
//   font-weight: 400;
//   padding: 0.78em 1.57em;
//   display: inline-block;
// }
// header ul li a:hover {
//   color: var(--ondc-primary-2);
// }
// header ul li a:active {
//   color: var(--ondc-secondary-1);
// }
// header .stick {
//   width: 0.085em;
//   height: 1.5em;
//   background-color: #04836d;
// }
// header #login {
//   padding-right: 0;
// }

// @media only screen and (max-width: 425px) {
//   header .stick,
//   .register {
//     display: none;
//   }
// } 
/* HERO SECTION STARTS */
/* HERO SECTION ENDS */
/* ABOUT ONDC SECTION STARTS*/
/* ABOUT ONDC SECTION ENDS*/
/* Easypay ONDC SECTION STARTS*/
/* Easypay ONDC SECTION ENDS*/
/* SELLER WORK SECTION STARTS*/
/* SELLER WORK SECTION ENDS*/
/* BENEFITs SECTION STARTS*/
/* BENEFITs SECTION ENDS*/
/* SELL ON ONDC SECTION STARTS*/
/* .steps-container .step-container .sell-icon {
    width: 45px;
  } */
/* SELL ON ONDC SECTION ENDS*/
/* SELLER MANAGEMENT SOLUTION ADVERTISEMENT SECTION STARTS*/
/* SELLER MANAGEMENT SOLUTION ADVERTISEMENT SECTION ENDS*/
/* SELLER MANAGEMENT SOLUTION SECTION STARTS*/
/* SELLER MANAGEMENT SOLUTION SECTION ENDS*/
/* INVITATION SECTION STARTS*/
/* INVITATION SECTION ENDS*/
/* SELLERS ONBOARDING IMAGE */
/* FOOTER STARTS */
/* #footer .footer-link:visited {
  color: rgb(156, 12, 156);
} */
/* FOOTER ENDS */
/* copyright-row */
/* copyright-row ends*/
/* Common media queries starts */
/* header, */
/* header, */
/* header, */
/* header img {
    width: 100px;
  } */
/* Common media queries ends */
:root {
	--ondc-primary-1: #195a9a;
	--ondc-primary-2: #0389e9;
	--ondc-primary-3: #e9f6ff;
	--ondc-secondary-1: #04836d;
	--ondc-secondary-2: #1cbfa2;
	--ondc-secondary-3: #1fd6b6;
	--ondc-accent-1: #e32028;
	--ondc-accent-2: #f05a60;
	--ondc-accent-3: #ffb1b1;
	--ondc-tagline: #2a2a2d;
	--ondc-text-900: #61636e;
	--ondc-text-700: #898c99;
	--ondc-text-500: #a6a9b9;
	--ondc-text-300: #bdc0d0;
	--ondc-text-100: #d9dced;
	--ondc-button-text: #fff;
     --z-index-header: 10;
	--z-index-button: 1;
}
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Lato ;
}
#ondc-page{
	background-color: #fff !important;
}
html {
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
	background-color: #fff;
}

section {
	max-width: 1440px;
	margin: auto;
	margin-bottom: 80px;
	padding-inline: 80px;
}
.headline {
	color: var(--ondc-primary-1);
	font-family: Raleway;
	font-weight: 700;
	font-size: 32px;
	margin-bottom: 25px;
}
.content-text {
	color: var(--ondc-text-900);
	font-family: Lato;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	word-spacing: 1px;
}
.ondc-button {
	background-color: var(--ondc-secondary-3);
	color: var(--ondc-button-text);
	padding: 0.39em 1.22em;
	font-family: Lato;
	font-size: 18px;
	font-weight: 500;
	border: 0;
	border-radius: 1.22em;
	display: flex;
	align-items: center;
	z-index: var(--z-index-button);
	&:hover {
		background-color: var(--ondc-primary-2);
	}
}
.sell-link {
	display: inline-block;
	text-decoration: none;
	&:active {
		.ondc-button {
			background-color: var(--ondc-secondary-3);
		}
	}
}
.easypay-logo {
	width: 120px;
}
header {
	padding: 18px 80px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: var(--z-index-header);
	background-color: #fff;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		font-size: 14px;
		li {
			display: inline-block;
			a {
				text-decoration: none;
				color: var(--ondc-secondary-1);
				font-family: Lato;
				font-weight: 400;
				padding: 0.78em 1.57em;
				display: inline-block;
				&:hover {
					color: var(--ondc-primary-2);
				}
				&:active {
					color: var(--ondc-secondary-1);
				}
			}
		}
	}
	.stick {
		width: 0.085em;
		height: 1.5em;
		background-color: #04836d;
	}
	#login {
		padding-right: 0;
	}
}
@media only screen and (max-width: 425px) {
	header {
		.stick {
			display: none;
		}
	}
	.register {
		display: none;
	}
}

#hero {
	position: relative;
	padding: 0;
	// margin-top: 100px;
	#hero-img {
		width: 100%;
	}
   
	.tagline {
		font-size: 40px;
		font-family: Raleway;
		font-weight: 500 !important;
		margin-bottom: 18px;
		color: var(--ondc-tagline);
		span {
			&:nth-child(1) {
				font-size: 48px;
				font-weight: 700;
				color: var(--ondc-secondary-1);
			}
			&:nth-child(2) {
				font-family: Lato;
				font-weight: 700;
				color: var(--ondc-primary-2);
			}
		}
	}
	.ondc-button {
		padding-right: 0.39em;
		img {
			width: 22px;
			margin-left: 13px;
		}
	}

    
}
#hero-content {
    padding-inline: 80px;
    position: absolute;
    top: 35.45%;
    width: 747px;
}

#about-ondc {
	.content {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		gap: 25px;
	}
	img {
		width: 230px;
	}
}
#easypay-ondc {
	.content {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 20px;
		.tabletline {
			display: none;
		}
	}
	.img-container {
		flex-basis: 50%;
	}
	img {
		width: 374px;
		display: block;
		margin-inline: auto;
	}
	.tag {
		font-size: 0.58em;
	}
	.content-text {
		flex-basis: 50%;
	}
}
#seller-work {
	.content {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
		gap: 25px;
		.desc-img {
			width: 654px;
		}
	}
	.gif-container {
		.desc-img {
			&:nth-child(1) {
				display: none;
			}
		}
		&:hover {
			.desc-img {
				&:nth-child(2) {
					display: none;
				}
				&:nth-child(1) {
					display: block;
				}
			}
		}
	}
	.content-text {
		.work {
			display: flex;
			gap: 20px;
			margin-bottom: 2em;
			p {
				margin: 0;
			}
		}
	}
	.work {
		img {
			width: 24px;
		}
	}
}
#benefits {
	padding-block: 45px 50px;
	background-color: var(--ondc-primary-3);
	.headline {
		text-align: center;
		font-weight: 600;
		margin-bottom: 0.94em;
		color: var(--ondc-primary-2);
	}
	.card-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		gap: 2%;
	}
	.ondc-card {
		border: 0.5px solid var(--ondc-primary-2);
		padding: 1em 1.1em;
		background-color: #fff;
		border-radius: 10px;
		width: 32%;
		.head {
			font-size: 22px;
			--head-line-height: 1.45em;
			line-height: var(--head-line-height);
			font-family: Raleway;
			font-weight: 600;
			margin-bottom: 8px;
		}
		.content-text {
			line-height: 28px;
		}
	}
}
.img-steps-container {
	margin-top: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 30px;
	.sell-main-img {
		width: 400px;
		border-radius: 30px;
	}
	.steps-container {
		display: flex;
		flex-direction: column;
		gap: 35px;
	}
}
.steps-container {
	.step-container {
		display: flex;
		align-items: center;
		gap: 15px;
		.sell-icon {
			width: 70px;
		}
		.step-row {
			display: flex;
			align-items: flex-end;
		}
	}
	.step-row {
		.number {
			font-size: 70px;
			font-weight: 700;
			color: var(--ondc-text-100);
			line-height: 0.8em;
		}
	}
}
.step-info {
	.step-headline {
		font-size: 20px;
		font-weight: 700;
		display: block;
	}
	.step-details {
		color: var(--ondc-text-900);
		display: block;
	}
}
#sms-adv {
	background-image: url(../../../assets/images/ondc/images/solution-back.jpg);
	background-size: cover;
	padding: 50px 0;
	#adv-background {
		background-color: rgba(255, 255, 255, 0.84);
		padding-inline: 80px;
		padding-block: 45px 50px;
		text-align: center;
		.headline {
			margin-bottom: 30px;
			line-height: 42px;
			color: var(--ondc-tagline);
		}
		.content-text {
			font-size: 22px;
			line-height: 1.6em;
			margin-bottom: 0.9em;
		}
		.ondc-button {
			margin: auto;
		}
	}
}
#sms {
	.image-benefits-container {
		display: flex;
		align-items: center;
		margin-top: 70px;
		.sms-img {
			width: 300px;
			display: block;
			margin-inline: auto;
		}
		.benefits-container {
			margin-left: 95px;
			list-style-image: url(../../../assets/images/ondc/icons/bullet-triangle.png);
		}
	}
	.benefits-container {
		.benefit {
			font-family: Lato;
			font-weight: 600;
			font-size: 20px;
			line-height: 1.42em;
			margin-bottom: 1.6em;
			padding-left: 1em;
			color: var(--ondc-secondary-1);
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
#invitation {
	margin-bottom: 20px;
}
#sellers-img-container {
	width: 100%;
	padding-inline: 0;
	.sellers-img {
		width: 100%;
	}
}
#footer {
	background-color: var(--ondc-primary-3);
	margin-bottom: 0;
	padding-block: 60px;
	font-size: 16px;
	.footer-links-container {
		display: flex;
		justify-content: space-between;
		.footer-columns-container {
			display: flex;
			gap: 80px;
			flex-wrap: wrap;
		}
	}
	.footer-column {
		h4 {
			margin-bottom: 8px;
		}
	}
	.footer-link {
		display: block;
		text-decoration: none;
		color: var(--ondc-tagline);
		margin-bottom: 5px;
		&:hover {
			color: var(--ondc-primary-2);
		}
		&:active {
			color: var(--ondc-tagline);
		}
	}
	.social-media {
		display: flex;
		gap: 40px;
		align-items: start;
		img {
			height: 25px;
		}
		.footer-link {
			margin-bottom: 0;
		}
	}
	.download {
		margin-top: 20px;
		h4 {
			margin-bottom: 5px;
		}
		img {
			width: 220px;
		}
	}
	.disclaimer-container {
		margin-top: 30px;
		p {
			font-size: 14px;
			margin-bottom: 5px;
			font-weight: 600;
		}
		div {
			font-size: 13px;
			line-height: 16px;
		}
	}
}
.copyright-row {
	padding-block: 20px;
	background-color: #1c3d70;
	color: var(--ondc-button-text);
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	gap: 10px;
	padding: 20px 0;
	max-width: 100% !important;
	.certifications {
		display: flex;
		gap: 5px;
		.certificate {
			display: flex;
			align-items: center;
			gap: 2px;
		}
	}
}
.certificate {
	img {
		height: 40px;
	}
}
.inner-footer-section {
	padding-inline: 0 !important;
	max-width: 100% !important;
}
.copyright-section {
	margin-bottom: 0 !important;
}

@media only screen and (max-width: 1200px) {
	#hero-content {
		width: 647px;
	}
	#hero {
		.tagline {
			font-size: 32px;
			span {
				&:nth-child(1) {
					font-size: 40px;
				}
			}
		}
	}
}
@media only screen and (max-width: 1024px) {
	#hero-content {
		width: 467px;
		padding-inline: 40px;
	}
	#hero {
		.tagline {
			font-size: 26px;
			span {
				&:nth-child(1) {
					font-size: 32px;
				}
			}
		}
	}
	#easypay-ondc {
		img {
			width: 300px;
		}
	}
	.img-steps-container {
		gap: 20px;
		.sell-main-img {
			width: 250px;
			border-radius: 20px;
		}
		.steps-container {
			gap: 30px;
		}
	}
	.steps-container {
		.step-container {
			gap: 5px;
			.sell-icon {
				width: 50px;
			}
		}
	}
	#sms {
		.image-benefits-container {
			.benefits-container {
				margin-left: 70px;
			}
		}
	}
	#footer {
		.footer-links-container {
			.footer-columns-container {
				gap: 50px;
			}
		}
		.social-media {
			gap: 30px;
		}
	}
	section {
		padding-inline: 40px;
	}
	#sms-adv {
		#adv-background {
			padding-inline: 40px;
		}
	}
}
@media only screen and (max-width: 768px) {
	html {
		scroll-padding-top: 60px;
	}
	section {
		margin-bottom: 50px;
		padding-inline: 30px;
	}
	.headline {
		font-size: 26px;
		margin-bottom: 25px;
	}
	.content {
		.desc-img {
			margin-bottom: 15px;
		}
	}
	.content-text {
		line-height: 20px;
		font-size: 16px;
	}
	.easypay-logo {
		width: 100px;
	}
	#hero {
		background-image: linear-gradient(var(--ondc-accent-3), transparent);
		height: 404px;
		#hero-img {
			display: none;
		}
		.tagline {
			font-size: 32px ;
			span {
				&:nth-child(1) {
					font-size: 38px;
				}
			}
		}
	}
	#hero-content {
		top: unset;
		bottom: 30px;
		width: 603px;
		padding-inline: 30px;
	}
	#about-ondc {
		.content {
			display: block;
		}
		img {
			width: 150px;
			display: block;
			margin-inline: auto;
		}
	}
	#easypay-ondc {
		.content {
			display: block;
			.tabletline {
				display: block;
			}
		}
		img {
			width: 225px;
		}
		.content-text {
			.headline {
				display: none;
			}
		}
	}
	#seller-work {
		.content-text {
			.work {
				gap: 15px;
			}
		}
		.work {
			img {
				width: 20px;
			}
		}
	}
	#benefits {
		.ondc-card {
			.head {
				font-size: 18px;
			}
			.content-text {
				line-height: 20px;
			}
		}
	}
	.img-steps-container {
		flex-direction: column;
		margin-top: 30px;
		.sell-main-img {
			display: none;
		}
		.steps-container {
			gap: 25px;
		}
	}
	.step-info {
		.step-headline {
			font-size: 16px;
			margin-bottom: 1px;
		}
		.step-details {
			font-size: 14px;
			line-height: 1.2;
		}
	}
	.steps-container {
		.step-row {
			.number {
				font-size: 65px;
			}
		}
	}
	#sms-adv {
		padding: 40px 0;
		#adv-background {
			padding-block: 25px 30px;
			padding-inline: 30px;
			.headline {
				margin-bottom: 20px;
				line-height: 34px;
			}
			.content-text {
				font-size: 18px;
			}
		}
	}
	#sms {
		.image-benefits-container {
			flex-direction: column;
			margin-top: 30px;
			.sms-img {
				width: 200px;
				margin-bottom: 30px;
			}
			.benefits-container {
				margin-left: 25px;
                padding-left: 0;
			}
		}
		.benefits-container {
			.benefit {
				font-size: 16px;
			}
		}
	}
	#footer {
		padding-bottom: 30px;
		.footer-links-container {
			flex-direction: column;
			gap: 30px;
			.footer-columns-container {
				gap: 30px;
				.footer-column {
					min-width: 180px;
				}
			}
		}
	}
}
@media only screen and (max-width: 603px) {
	#hero-content {
		top: unset;
		bottom: 30px;
		width: 350px;
	}
}
@media only screen and (max-width: 425px) {
	#hero-content {
		top: unset;
		bottom: 30px;
		width: 320px;
		padding-inline: 15px;
	}
	section {
		padding-inline: 15px;
	}
	#sms-adv {
		#adv-background {
			padding-inline: 15px;
		}
	}
}
@media only screen and (max-width: 950px) {
	#seller-work {
		.content {
			display: block;
			.desc-img {
				width: 470px;
				display: block;
				margin-inline: auto;
				margin-block: 45px 15px;
			}
		}
	}
}
@media only screen and (max-width: 1225px) {
	#seller-work {
		.content {
			.desc-img {
				width: 450px;
			}
		}
		.content-text {
			.work {
				margin-bottom: 0.88em;
			}
		}
	}
}
@media only screen and (max-width: 530px) {
	#seller-work {
		.content {
			.desc-img {
				width: 100%;
			}
		}
	}
}
@media only screen and (max-width: 900px) {
	#benefits {
		.card-container {
			flex-direction: column;
		}
		.ondc-card {
			width: 100%;
			margin-bottom: 1.25em;
			&:last-child {
				margin-bottom: 0;
			}
			.head {
				height: unset;
			}
		}
	}
}
@media only screen and (max-width: 1394px) {
	#benefits {
		.ondc-card {
			.head {
				height: calc(2 * var(--head-line-height));
			}
		}
	}
}
@media only screen and (max-width: 500px) {
	.copyright-row {
		flex-direction: column;
	}
}

/* FOOTER STARTS */
/* #footer .footer-link:visited {
    color: rgb(156, 12, 156);
  } */
/* FOOTER ENDS */
/* copyright-row */
/* copyright-row ends*/
/* Common media queries starts */
.footer-section {
	margin-bottom: 80px;
}
.inner-footer-section {
	max-width: 1220px;
	margin: auto;
	padding-inline: 15px;
}
#footer {
	background-color: #fff !important;
	margin-bottom: 0;
	padding-block: 60px;
	font-size: 16px;
	box-shadow: 0px 2px 8px 0 rgba(0,0,0,0.5);
	.footer-links-container {
		display: flex;
		justify-content: space-between;
		.footer-columns-container {
			display: flex;
			gap: 80px;
			flex-wrap: wrap;
		}
	}
	.footer-column {
		h4 {
			margin-bottom: 8px;
			font-family: "Lato", sans-serif;
			font-size: 16px !important;
			font-weight: 600;
		}
		line-height: 1.1 !important;
	}
	.footer-link {
		display: block;
		text-decoration: none;
		color: var(--ondc-tagline);
		margin-bottom: 9px;
		&:hover {
			color: var(--ondc-primary-2);
		}
		&:active {
			color: var(--ondc-tagline);
		}
	}
	.social-media {
		display: flex;
		gap: 40px;
		align-items: start;
		img {
			height: 25px;
		}
		.footer-link {
			margin-bottom: 0;
		}
	}
	.download {
		margin-top: 20px;
		h4 {
			margin-bottom: 5px;
			font-family: "Lato", sans-serif;
			font-size: 16px !important;
			font-weight: 600;
		}
		img {
			width: 220px;
		}
	}
	.disclaimer-container {
		margin-top: 30px;
		p {
			font-size: 14px;
			margin-bottom: 5px;
			font-weight: 600;
		}
		div {
			font-size: 13px;
			line-height: 16px;
		}
	}
}
.copyright-section {
	background-color: #1c3d70;
}
.copyright-row {
	padding-block: 20px;
	color: var(--ondc-button-text);
	font-size: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	gap: 10px;
	max-width: 1220px;
	margin: auto;
	padding: 20px 15px;
	.certifications {
		display: flex;
		gap: 5px;
		.certificate {
			display: flex;
			align-items: center;
			gap: 2px;
		}
	}
}
.certificate {
	img {
		height: 40px;
	}
}
.copyright-text-container {
	margin-right: 90px;
}
@media only screen and (max-width: 768px) {
	.footer-section {
		margin-bottom: 50px;
		padding-inline: 30px;
	}
	.headline {
		font-size: 26px;
		margin-bottom: 25px;
	}
	.content {
		.desc-img {
			margin-bottom: 15px;
		}
	}
	#footer {
		padding-bottom: 30px;
		.footer-links-container {
			flex-direction: column;
			gap: 30px;
			.footer-columns-container {
				gap: 30px;
				.footer-column {
					min-width: 180px;
				}
			}
		}
	}
	header {
		padding-inline: 30px;
	}
	#hero-content {
		padding-inline: 30px;
	}
	#sms-adv {
		#adv-background {
			padding-inline: 30px;
		}
	}
}
@media only screen and (max-width: 1024px) {
	#footer {
		.footer-links-container {
			.footer-columns-container {
				gap: 50px;
			}
		}
		.social-media {
			gap: 30px;
		}
	}
	header {
		padding-inline: 40px;
	}
	.footer-section {
		padding-inline: 40px;
	}
	#hero-content {
		padding-inline: 40px;
	}
	#sms-adv {
		#adv-background {
			padding-inline: 40px;
		}
	}
	.inner-footer-section {
		padding-inline: 0;
	}
	.copyright-row {
		padding-inline: 20px 0;
	}
}
@media only screen and (max-width: 500px) {
	.copyright-row {
		flex-direction: column;
	}
	.copyright-text-container {
		margin-right: 0;
	}
}
@media only screen and (max-width: 425px) {
	header {
		padding-inline: 15px;
		img {
			width: 100px;
		}
	}
	.footer-section {
		padding-inline: 15px;
	}
	#hero-content {
		padding-inline: 15px;
	}
	#sms-adv {
		#adv-background {
			padding-inline: 15px;
		}
	}
}

