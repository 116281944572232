$easypayred: #e22429;
$easypayyellow: #ffc300;
$easypayblue: #003a72;
$easypaygrey: #ecf0f1;

:export {
  easypayred: $easypayred;
  easypayyellow:   $easypayyellow;
  easypayblue: $easypayblue;
  easypaygrey: $easypaygrey;
}
