@import "rsuite/dist/rsuite.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}



/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-toast-container {
  z-index: 10000 !important;
}

//input field

.form-error {
  font-size: 12px;
  color: red;
}

.steps-form-field {
  position: relative;
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 10px;
  line-height: normal;

  .steps-form-input,
  .steps-form-input-select {
    font-size: 14px;
    font-weight: 600;
    border: 0;
    color: var(--gray-700);
    border-bottom: 1px solid #4F91F5;
    // -webkit-appearance: none;
    border-radius: 0;
    padding: 0 0 1px 0;
    cursor: text;
    background: transparent;
  }

  .steps-form-label,
  .steps-form-label-select {
    font-size: 14px;
    font-weight: 400;
  }
}


.steps-form-label,
.steps-form-label-select,
.steps-form-input,
.steps-form-input-select {
  transition: all 0.3s;
  touch-action: manipulation;
}

.steps-form-input,
.steps-form-input-select:focus {
  outline: 0;
  // border-bottom: 1px solid var(--gray-700);
}

.steps-form-input:placeholder-shown+.steps-form-label {
  cursor: text;
  transform: translate(0, 0.8rem);
}

.steps-form-input-select:placeholder-shown+.steps-form-label-select {
  cursor: text;
  transform: translate(0, 1.7rem);
  // transform: translate(0, 1.125rem);
}

.steps-form-input+.steps-form-label {
  // cursor: text;
  transform: translate(0, 1.7rem);
  // transform: translate(0, 1.125rem);
  // transform: translate(0, 2.125rem) scale(1.5);
}

.steps-form-input-select+.steps-form-label-select {
  // cursor: text;
  transform: translate(0, 1.7rem);
}

.steps-form-input:placeholder-shown {
  border-bottom: 1px solid var(--gray-700) !important;
}

/** By default, the placeholder should be transparent. Also, it should 
inherit the transition.*/

// ::-webkit-input-placeholder {
//   opacity: 0;
//   // transition: inherit;
// }

.steps-form-input-border {
  border-bottom: 1px solid #4F91F5 !important;
}

/** Show the placeholder when the input is focused. */
// .steps-form-input:focus::-webkit-input-placeholder,
// .steps-form-input-select:focus::-webkit-input-placeholder {
//   opacity: 1;
// }

/** When the element is focused, remove the .steps-form-label transform.
  Also, do this when the placeholder is _not_ shown, i.e. when 
  there's something in the input at all.*/
.steps-form-input:not(:placeholder-shown)+.steps-form-label,
.steps-form-input:focus+.steps-form-label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 3px;
}

.steps-form-input-select:not(:placeholder-shown)+.steps-form-label,
.steps-form-input-select:focus+.steps-form-label-select {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  font-size: 12px;
}


.downArrow {
  position: absolute;
  right: 0;
  width: 8px;
  height: 11.244px;
  transform: translate(-8px, -21px);
}

.form-group {
  display: flex;
  height: 56px;
  width: 100%;
  margin-top: -18px;
}

.control-label {
  color: var(--gray-700);
  pointer-events: none;
  position: absolute;
  transform: translate3d(0, 33px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  font-size: 14px;
  font-weight: 400;
}

.form-group.focused .control-label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  font-size: 12px;
  margin-top: 16px;
}

.form-control:focus {
  border-color: var(--gray-700);
}

.form-control {
  align-self: flex-end;
  display: block;
  background: transparent;
  -webkit-transition:
    border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}

.form-control:focus::-webkit-input-placeholder {
  transition: none;
}

select,
select.form-control {
  font-size: 14px;
  font-weight: 600;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border-bottom: 1px solid var(--gray-700);
  color: var(--gray-700);
}

select:focus,
select.form-control:focus {
  box-shadow: inset 0 0 0 var(--gray-700);
}

.review-content-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40%;
  width: 75%;
  flex-direction: column;

  .thanks-img {
    height: 90px;
    width: 90px;
  }
}

.review-content {
  font-size: 14px;
  color: var(--gray-700);
}

.prevStep {
  background: var(--primary-6) !important;
  color: var(--success-1) !important;
}

.disabled {
  background: var(--primary-6) !important;
  color: var(--gray-700) !important;
}

.rs-picker-menu {
  z-index: 11;
}

.branch-address {
  font-size: 10px;
  color: red;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .scroll-div-responsive {
    height: calc(100% - 55px);
    overflow-y: scroll;
    overflow-x: clip;
  }
}

.scroll-div {
  height: calc(100% - 45.39px);
}

.baas-form-control {
  display: block;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  padding: 4px 6px;
  border: 1.5px solid #acacac;
  outline: 0;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.baas-form-control:focus {
  border-color: #6a6a6a;
}

.baas-form-label {
  color: var(--gray-700);
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}

.baas-form-error {
  font-size: 12px;
  color: red;
}

.baas-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

}

.ondc-headline {
  font-size: 20px;
  margin-bottom: 15px;
  // border-bottom: 2px solid var(--rs-primary-700);
  .back-btn {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    padding-right: 10px;
  }
}

.product-headline {
  margin-bottom: 5px !important;
}

.ondc-note {
  font-style: italic;
  font-size: 12px;
  color: var(--gray-500);
}

.list-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;

  .refresh-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;

    .refresh-icon {
      width: 16px;
      height: 16px;
      transform: rotate(0deg);
    }

    .refresh-icon:active {
      transform: rotate(180deg);
    }
  }
}

// .list-header {
//   justify-content: unset;

//   .refresh-btn {
//       cursor: pointer;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       border-radius: 50%;
//       width: 35px;
//       height: 35px;

//       .refresh-icon {
//           width: 16px;
//           height: 16px;
//           transform: rotate(0deg);
//       }

//       .refresh-icon:active {
//           transform: rotate(180deg);
//       }
//   }

//   input {
//       margin-left: auto;
//       flex-basis: 500px;
//       width: unset;
//       min-width: 200px;
//   }
// }

.list-table {
  width: 100%;

  .list-head-row,
  .list-body-row {
    display: flex;
    padding: 10px 0;
    cursor: pointer;
    text-decoration: none;
  }

  .list-head-row {
    color: #868686;

    th {
      font-weight: 400;
    }
  }

  .list-body-row {
    border-bottom: 1px solid #c2c2c2;
  }

  .list-body-row:hover {
    box-shadow: 0 1px 3px 0px #868686;
  }

  .disabled-row {
    opacity: 0.5;
    pointer-events: none;
  }

  td.disabled-td {
    // opacity: 0.5;
    pointer-events: none;

    .row-action-btn {
      color: rgb(214, 214, 214) !important;
      background-color: rgb(237, 237, 237);
    }
  }



  .row-action-btn {
    background-color: #ffe0ba;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
  }

  .row-action-btn:hover {
    color: rgb(253, 95, 95) !important;
    border: 1.5px solid rgb(253, 95, 95);
  }
}

.status-box {
  font-weight: 400;
  display: inline;
  padding-block: 2px;
  padding-inline: 6px;
  border-radius: 4px;
  font-size: 12px;
  color: #FFF;
  white-space: nowrap;
}

.status-inactive {
  background-color: #FF6D60;
}

.status-active {
  background-color: #66bb6a;
}

.status-liquid {
  background-color: #8d00ad;

}

.status-pending {
  background-color: #FED049;
}

.status-accept {
  background-color: #00A9FF;
}

.status-apply {
  background-color: #13a6ab;
}

.status-new {
  background-color: #868686;
}

.box {
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 10px;
}

.baas-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .pagination-tabs {
    .page-tab {
      display: inline-block;
      padding: 4px 10px;
      border: 1.5px solid #acacac;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 3px;
    }

    .pagination-btn:hover {
      background-color: #efeeee;
    }
  }

}

// .search-input {
//   width: 100%;
// }
.status-modal {
  padding: 15px;
  font-size: 14px;

  // .status-question {
  //     font-size: 16px;
  // }

  .status-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .status-btn {
      padding: 3px 20px;
      border-radius: 5px;
    }

    .btn-yes {
      background-image: var(--btn-bg-color);
      color: #fff;
    }

    .btn-no {
      background: none;
      color: #000090;
      border: 1.5px solid #000090;
    }
  }
}

.action-option {
  display: flex;
  width: 200px;
  gap: 4px;

  .action-icon {
    display: flex;
    flex: 0.2;
    justify-content: center;
    align-items: center;
  }

  .action-label {
    font-size: 14px;
    margin-left: 1px;
    font-weight: 400;
  }

  #edit-action {
    color: #3F81E0;
  }

  #remove-action {
    color: #D0342C;
  }

  #update-action {
    color: green
  }
  #copy-action {
    color: #FED049
  }
}