.home-container {
  .top-navbar {
    border-bottom: 2px solid var(--border-color);
    left: 202px;
    transition: left 0.25s ease;
    z-index: 10;
    background: var(--container-color);
    @media only screen and (max-width: 992px) {
      left: 0;
    }
    .top-navbar-brand-wrapper {
      padding-left: 1.3rem;
    }
    .top-navbar-menu-wrapper {
      width: 100%;
      height: 68px;
      padding: 0 15px;
      @media only screen and (max-width: 345px) {
        font-size: 12px;
      }
      .navbar-toggler {
        padding: 0.25rem 0.75rem;
        border-radius: 5px;
        @media only screen and (max-width: 992px) {
          display: none;
        }
        .hamburger {
          color: #6c7293 !important;
        }
        .sts-tooltip {
          visibility: hidden;
          position: relative;
          color: rgb(86, 86, 86);
          box-shadow: 0px 0px 3px 1px rgba(207, 207, 207, 0.5);
          background-color: #fff;
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 14px;
          z-index: 1;
          opacity: 0;
          transition: opacity 0.3s ease, visibility 0.3s ease;
          bottom: -30px;
          left: 10%;
          transform: translateX(-50%);
        }

        &:hover .sts-tooltip {
          visibility: visible;
          opacity: 1;
          z-index: 50;
        }
      }
      .nav {
        .nav-item {
          .qr-pattern {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            .qr-pattern-container {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              .qr-tooltip {
                display: none;
                visibility: hidden;
                color: rgb(86, 86, 86);
                box-shadow: 0px 0px 3px 1px rgba(207, 207, 207, 0.5);
                border-radius: 6px;
                background-color: #fff;
                text-align: center;
                font-size: 14px;
                padding: 5px;
                border-radius: 4px;
                position: absolute;
                left: 50%;
                bottom: -33px;
                transform: translateX(-50%);
                z-index: 1;
                opacity: 0;
                transition: opacity 0.3s;
                white-space: nowrap;
              }

              &:hover .qr-tooltip {
                display: flex;
                flex-wrap: nowrap;
                visibility: visible;
                opacity: 1;
              }
            }

            .download-icon {
              position: absolute;
              font-size: 14px;
              color: #555;
              :hover {
                background-color: #fff;
                padding: 5px;
                border-radius: 5px;
              }
            }
          }
          .playStore {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            .playStore-container {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              .playStore-tooltip {
                display: none;
                visibility: hidden;
                color: rgb(86, 86, 86);
                box-shadow: 0px 0px 3px 1px rgba(207, 207, 207, 0.5);
                border-radius: 6px;
                background-color: #fff;
                text-align: center;
                font-size: 14px;
                padding: 5px;
                border-radius: 4px;
                position: absolute;
                left: 50%;
                bottom: -40px;
                transform: translateX(-50%);
                z-index: 1;
                opacity: 0;
                transition: opacity 0.3s;
                white-space: nowrap;
              }

              &:hover .playStore-tooltip {
                display: flex;
                flex-wrap: nowrap;
                visibility: visible;
                opacity: 1;
              }
            }

            .download-icon {
              position: absolute;
              font-size: 14px;
              color: #555;
              :hover {
                background-color: #fff;
                padding: 5px;
                border-radius: 5px;
              }
            }
          }
          .radius {
            border-radius: 50%;
          }
          .nav-link {
            cursor: pointer;
            position: relative;
            .menu-title {
              overflow: hidden;
              white-space: nowrap;
              .arrow {
                margin-left: 15px;
                display: inline-block;
                transform: rotate(0deg);
              }
              .collapse-arrow {
                display: none;
              }
              .expand-arrow {
                display: inline-block;
              }
            }
            .sts-tooltip {
              visibility: hidden;
              position: absolute;
              color: rgb(86, 86, 86);
              box-shadow: 0px 0px 3px 1px rgba(207, 207, 207, 0.5);
              border-radius: 6px;
              background-color: #fff;
              padding: 5px 10px;
              border-radius: 5px;
              font-size: 14px;
              z-index: 1;
              opacity: 0;
              transition: opacity 0.3s ease, visibility 0.3s ease;
              bottom: -30px;
              transform: translateX(-50%);
            }
          }
          .nav-link:hover {
            .sts-tooltip {
              visibility: visible;
              opacity: 1;
              z-index: 50;
            }
          }
          #profile-dropdown {
            padding: 1rem;
            border-radius: 5px;
            z-index: 2;
            border: 1px solid var(--border-color);
            cursor: default;
            background: var(--container-color);
            display: none;
            h6.p-drop-item {
              color: #6c7293;
            }
            .p-drop-item {
              display: block;
              padding: 0.5rem 1rem;
              text-decoration: none;
              .my-logout-color {
                color: red !important;
              }
              .menu-title {
                white-space: nowrap;
              }
              .menu-title:hover {
                text-decoration: underline;
                color: #8fbd56;
              }
            }
          }
          #seller-dropdown {
            padding: 1rem;
            border-radius: 5px;
            z-index: 2;
            border: 1px solid var(--border-color);
            cursor: default;
            background: var(--container-color);
            display: none;
            h6.p-drop-item {
              color: #6c7293;
            }
            .p-drop-item {
              display: block;
              padding: 0.5rem 1rem;
              text-decoration: none;
              .my-logout-color {
                color: #6c7293 !important;
              }
              .menu-title {
                white-space: nowrap;
              }
              .menu-title:hover {
                text-decoration: underline;
                color: #8fbd56;
              }
            }
          }
          .show {
            display: block !important;
            transition: display 1s ease;
          }
          .nav-link {
            color: var(--text-color);
            .menu-icon {
              display: flex;
              align-items: center;
              font-size: 22px;
              border-radius: 5px;
            }
            .moon-theme {
              color: #000 !important;
            }
          }
        }
        .nav-item:has(.show) #down-arrow {
          transform: rotate(180deg);
        }
        .right-nav-toggler {
          display: none;
          @media only screen and (max-width: 992px) {
            display: block;
          }
        }
      }
    }
  }
}
.sts-tooltip {
  visibility: hidden;
  position: absolute;
  color: rgb(86, 86, 86);
  box-shadow: 0px 0px 3px 1px rgba(207, 207, 207, 0.5);
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  bottom: -30px; /* Positioning the tooltip below the icon */
  left: 50%;
  transform: translateX(-50%); /* Centering the tooltip horizontally */
}

.navbar-toggler:hover .sts-tooltip {
  visibility: visible;
  opacity: 1;
  z-index: 50;
}

.sidenav-icon-only .home-container {
  .top-navbar {
    left: 70px;
  }
}

#profile-dropdown {
  transition: display 1s ease;
}
