.footer-bottom {
    padding: 0 15px;
    font-family: rubik;
    // bottom: 0;
    width: 100%;
    right: 0;
    background: var(--container-color);
    border-top: 2px solid rgba(200, 200, 200, 0.3);
    position: absolute;
    bottom: -51px;
    .footer-site-info {
        font-size: 92.86%;
        margin: 0 15px;
        text-decoration: underline;
    }
}
