*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Lato ;
}
body::-webkit-scrollbar{
	overflow-y: scroll;
	display: none;
}
#ondc-page{
	background-color: #fff !important;
}
html {
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
	background-color: #fff;
}

body:has(.login) {
    background-color: #fff!important;
}

section {
	max-width: 1440px;
	margin: auto;
	margin-bottom: 80px;
	padding-inline: 80px;
}

header {
	padding: 18px 80px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: var(--z-index-header);
	background-color: #fff;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		font-size: 14px;
		li {
			display: inline-block;
			a {
				text-decoration: none;
				// color: var(--ondc-secondary-1);
				color: #003A72;
				font-family: Lato;
				font-weight: 400;
				padding: 0.78em 1.57em;
				display: inline-block;
				&:hover {
					color: var(--ondc-primary-2);
				}
				&:active {
					color: var(--ondc-secondary-1);
				}
			}
		}
	}
	.stick {
		width: 0.085em;
		height: 1.5em;
		// background-color: #04836d;
		background-color: #003A72;
	}
	#login {
		padding-right: 0;
	}
}
@media only screen and (max-width: 425px) {
	header {
		.stick {
			display: none;
		}
	}
	.register {
		display: none;
	}
}

#login-box,#singup-box{
	perspective: 1000px;
}

@media only screen and (max-width:768px){
	.login #authetication-container{
		// display: flex !important; 
		// justify-content: center;
		flex-direction: column-reverse !important ;
	}
}

.login{
	margin-bottom: 80px;
}

@media (max-width: 768px) {
	.authetication-container {
	  flex-direction: column-reverse;
	}
  }

// #sigin-form{
// 	    transform: rotateY(180deg);
// 	    transition: transform 0.4s;
// 	    // backface-visibility: hidden;
// 	    transform-style: preserve-3d
	
// }


// #registration-form{
// 		transform: rotateY(-180deg);
// 		transition: transform .4s;
// 		// backface-visibility: hidden;
// 		transform-style: preserve-3d;
// }

