header {
	padding: 18px 80px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: var(--z-index-header);
	background-color: #fff;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		font-size: 14px;
		li {
			display: inline-block;
			a {
				text-decoration: none;
				color: #3A975C;
				font-family: Lato;
				font-weight: 400;
				padding: 0.78em 1.57em;
				display: inline-block;
				&:hover {
					color: var(--ondc-primary-2);
				}
				&:active {
					color: var(--ondc-secondary-1);
				}
			}
		}
	}
	.stick {
		width: 0.085em;
		height: 1.5em;
		// background-color: #04836d;
		background-color: #003A72;
	}
	#login {
		padding-right: 0;
	}
}
