.home-container {
  display: flex;
  // font-family: 'Open Sans', sans-serif;

  .footer-page-wrapper {
    width: calc(100% - 202px);
    position: relative;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
    .page-wrapper {
      width: 100%;
      min-height: 100vh;
      display: flex;
      transition: width 0.25s ease;

      .main-panel {
        width: 100%;
        min-height: 100vh;
        margin: 70px 0 0;
        padding: 20px;
        @media only screen and (max-width: 768px) {
          padding: 10px;
        }
        .title-main-page {
          font-size: 1.8rem;
          margin-bottom: 0;
          font-weight: 800;
        }
      }
    }
  }
}

.sidenav-icon-only {
  .home-container {
    .footer-page-wrapper {
      width: calc(100% - 70px);
    }
  }
}

.move-top {
  width: fit-content;
  z-index: 2;
}
