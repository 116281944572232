.desc-pos {
  &:first-child {
    padding-top: 40px;
  }

  .left-content-title {
    font-weight: 400;
    line-height: 2;
    font-size: 18px;
    margin-bottom: 24px;

  }

  .feature-box {
    padding-bottom: 50px;

    .feature-box-content {
      margin-bottom: 23px;
      display: flex;

      .feature-img {
        width: 60px;
        height: 60px;
      }

      h5 {
        font-size: 18px;
        font-weight: 500;
      }

      h6 {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        color: rgb(120, 120, 135);
      }
    }
  }

  .feature-hr {
    height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0) linear-gradient(to right, rgba(215, 215, 224, 0), rgba(215, 215, 224, 0.5), rgb(215, 215, 224), rgba(215, 215, 224, 0.5), rgba(215, 215, 224, 0)) repeat scroll 0% 0%;
  }

  .pointer-feat {
    margin-top: 24px;

    ul {
      li {
        margin-bottom: 12px;
      }
    }
  }

}


@media (max-width: 899px) and (min-width: 768px) {

  .ondc-register-form {
    width: 327px !important;
  }
}

@media only screen and (min-width:576px) {
  .container {
    max-width: 900px !important;
  }
}

@media only screen and (min-width:768px) {
  .container {
    max-width: 900px !important;
  }
}

@media only screen and (min-width:1200px) {
  .container {
    max-width: 1140px !important;
  }
}

.ondc-register-form {
  // position: relative;
  // margin: 0 auto;
  // top: 41px;
  margin-top: 10px;
  max-width: 427px;
  padding: 40px 30px 10px;
  border-radius: 10px;
  // background: #ebf5fc;
  margin-left: auto;
  background: none;
  // text-align: center;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

  // box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.8),
  // 5px 5px 10px rgba(0, 0, 0, 0.1);
  .main-title {
    font-family: "Baas Poppins";
    margin-bottom: 20px;
    color: #000;
    font-size: 1rem;
    font-weight: 300;
  }

  h4 {
    font-family: "Baas Poppins";
    color: #000;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 28px;
  }

  .input {
    margin-top: 24px;
    text-align: left;

    .inputBox {
      margin-top: 5px;
      flex-wrap: wrap;

      label {
        display: block;
        // color: #868686;
        color: #2c2a2a;
        font-size: 14px;
        margin-bottom: 5px;
      }

      input,
      .my-btn {
        width: 100%;
        // color: #003A72;
        color: #000;
        font-weight: 600;
        // background: #ebf5fc;
        border: none;
        outline: none;
        padding: 8px 27px 8px 42px;
        font-size: 14px;
        border: 1px solid #acacac;
        border-radius: 0.3em;
        font-size: 16px !important;

        &:disabled,
        &:disabled:hover,
        &:disabled:active {
          background-color: #fff;
          border-color: #ccc;
          opacity: 0.7;
          cursor: not-allowed;
        }

        &:focus {
          border: 1px solid #2c2a2a;
        }
      }

      input[type="submit"],
      .my-btn {
        // margin-top: 20px;
        box-shadow: -2px -2px 6px #dfdbdb, 2px 2px 6px rgba(0, 0, 0, 0.1);
        border-bottom: none;
        border-radius: 5px;
        background: #003a72;
        font-weight: 600;
        color: #fff;
      }

      input[type="submit"]:active,
      .my-btn:active {
        color: #006c9c;
        // margin-top: 20px;
        box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 1),
          inset 2px 2px 6px rgba(0, 0, 0, 0.1);
      }

      input::placeholder {
        color: #bdcada;
        font-size: 18px;
      }
    }

    .input-group {
      .form-control:disabled {
        background-color: #fff;
      }

      .signup-otp-btn {
        font-size: 14px;
      }

      .btn-submit {
        background: #003a72;
        color: #fff;

        &:hover {
          background: #003a72;
          color: #fff;
        }
      }

      .field-disable {
        color: gray !important;
      }
    }

  }

  .forget {
    margin-top: 30px;
    color: #555;
    font-size: 12px;

    a {
      color: #003a72;
    }

    @media only screen and (max-width: 425px) {
      text-align: center;
    }
  }

  .error {
    font-size: 0.8em;
    color: #e32020;
  }

  .hide-error {
    opacity: 0;
  }

  .error-spacer {
    height: 14px;
    display: inline-block;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }
}

// .btn-submit:hover {
//     background: #006c9c;
//     color: #fff;
// }



// for  flip functionality this code has to be uncommented

#registration-form {
  transform: rotateY(0deg);
  transition: transform 0.3s;
  // backface-visibility: hidden;
  transform-style: preserve-3d;
}


.register-pass {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 0;
  padding: 5px 10px 5px 5px;

  img {
    height: 20px;
    width: 20px;
  }
}