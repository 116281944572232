* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // font-family: sans-serif;
    font-family: "Open Sans";
  }
  body {
    background: #ebf5fc;
  }
  .ondc-login-form {
    // position: relative;
    max-width: 427px;
    padding: 40px 30px 30px;
    border-radius: 10px;
    // background: #ebf5fc;
    margin-top: 10px;
    margin-left: auto;
    text-align: center;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    // box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.8),
    // 5px 5px 10px rgba(0, 0, 0, 0.1);
    .welcome-text {
      color: #003a72;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 22px;
    }
    h2 {
      color: #003a72;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 4px;
    }
    h4 {
      font-size: 18px;
    }
  
    .input {
      margin-top: 20px;
      text-align: left;
      .inputBox {
        margin-top: 20px;
        flex-wrap: wrap;
        label {
          display: block;
          color: #868686;
          margin-bottom: 5px;
          font-size: 14px;
        }
        input,
        .my-btn {
          width: 100%;
          color: #000;
          font-weight: 600;
          background: none;
          border: none;
          outline: none;
          padding: 6px 10px;
          font-size: 14px;
          border-radius: 5px;
          border: 1px solid #acacac;
          &:focus {
            border: 1px solid #2c2a2a;
          }
        }
        .my-btn {
          height: 45px;
          &:active {
            color: #006c9c;
            margin-top: 0px;
            box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 1),
              inset 2px 2px 6px rgba(0, 0, 0, 0.1);
          }
          &:disabled,
          &:disabled:hover,
          &:disabled:active {
            background-color: #ccc;
            color: #fff;
            border-color: #ccc;
            cursor: not-allowed;
          }
        }
        input[type="submit"],
        .my-btn {
          margin-top: 20px;
          box-shadow: -2px -2px 6px #dfdbdb, 2px 2px 6px rgba(0, 0, 0, 0.1);
          border-bottom: none;
          border-radius: 5px;
          background: #003a72;
          font-weight: 600;
          color: #fff;
        }
        .my-btn.login-submit-btn {
          margin-top: 0px;
        }
        input::placeholder {
          color: #bdcada;
          font-size: 18px;
        }
        .text-danger {
          font-size: 14px;
        }
      }
    }
    .forget {
      margin-top: 15px;
      color: #555;
      text-align: right;
      a {
        color: #003a72;
        font-size: 14px;
      }
    }
  
    .seller-register {
      // margin-top: 30px;
      color: #555;
      text-align: left;
      a {
        color: #003a72;
        font-size: 14px ;
      }
    }
    @media only screen and (max-width: 768px) {
        margin: 10px auto 0;
    }
  }

  .ondc-login-pass{
    position: relative;
  }
  .password-toggle{
    position: absolute;
    right: 0;
    padding: 5px 10px 5px 5px;
  
    img{
    height: 20px;
    width: 20px;
    }
  }
  
  
  // for  flip functionality this code has to be uncommented
  
  #sigin-form {
    transform: rotateY(0deg);
    transition: transform 0.3s;
    transform-style: preserve-3d;
  }
  