:root {
  --bg-color :rgb(244, 245, 247);
  --text-color: #000;
  --border-color: rgb(234, 237, 241);
  --nav-bg-color: linear-gradient(90deg, #003a72, #e22429);
  --shadow-color: #fff;
  --card-bg-color: #fff;
  --container-color: #fff;
  --sub-container-color: #E8E8E8;
  --container-b-color: #eee;  
  --btn-bg-color : linear-gradient(125deg, #a72879, #064497);
  --bg-col: linear-gradient(90deg, #003A72, #E32028);
  --btn-col: #fff ;
  --home-img-bg-color: #fff;
  --doc-btn: #0552C5;
  --gray-500: #454444;
  --gray-700: #2C2A2A;
  --gray-900: #191919;
  --primary-1: #033E97;
  --primary-2: #0552C5;
  --primary-3: #317EF3;
  --primary-4:#4F91F5;
  --primary-6: #D0E3FF;
  --success-1: #068C03;
  --success-2: #1ABF16;
  --secondary-1: #E30909;
}

.dark-theme {
  --bg-color: #000;
  --text-color: #fff;
  --border-color:rgba(255, 255, 255, 0.15);
  --shadow-color: #000;
  --card-bg-color: #000;
  --container-color: #191c24;
  --sub-container-color: #12151e;
  --home-img-bg-color: #181B22;
}

@font-face {
  font-family: 'Baas Poppins';
  src: url('./assets/fonts/poppins/Poppins-Light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Baas Poppins';
  src: url('./assets/fonts/poppins/Poppins-Medium.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Baas Poppins';
  src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/fonts/nunitoSans/NunitoSans_10pt-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/fonts/nunitoSans/NunitoSans_10pt-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/fonts/nunitoSans/NunitoSans_10pt-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/fonts/nunitoSans/NunitoSans_10pt-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/fonts/nunitoSans/NunitoSans_10pt-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/fonts/nunitoSans/NunitoSans_10pt-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assets/fonts/nunitoSans/NunitoSans_10pt-ExtraBold.ttf');
  font-weight: 800 900;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/openSans/OpenSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/openSans/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/openSans/OpenSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/openSans/OpenSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/openSans/OpenSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/openSans/OpenSans-ExtraBold.ttf');
  font-weight: 800 900;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif!important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color)!important;
  color: var(--text-color)!important;
  color: var(--gray-700);
  line-height: normal;
  input{
    padding: 0;
    margin: 0;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    color: var(--gray-900);
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: initial;
  }
}







