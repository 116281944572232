#sidenav {
  width: 215px;
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  border-right: 2px solid var(--border-color);
  background: var(--container-color);

  .sidenav-brand-wrapper {
    padding: 0 0 0 1.3rem;
    height: 70px;
    font-size: 1.5rem;
    line-height: 48px;
    margin-right: 0;
    width: 200px;
    border-bottom: 2px solid var(--border-color);
    background: var(--container-color);
    z-index: 10;

    .brand-logo-mini {
      display: none;
    }
  }

  > .navbar-nav {
    padding-top: 90px;

    .nav-item {
      .nav-link {
        display: flex;
        align-items: center;
        padding: 0.8rem 10px 0.8rem 1.188rem;
        position: relative;
        text-decoration: none;
        height: 46px;
        cursor: pointer;
        color: var(--text-color);

        .menu-icon {
          width: 36px;
          min-width: 36px;
          // height: 31px;
          // display: inline-flex;
          // justify-content: center;
          // align-items: center;
          // border-radius: 100%;
          // margin-right: 0.5rem;
          // background: rgba(108, 114, 147, 0.2);
        }

        .menu-img {
          border: 1px solid var(--border-color);
          border-radius: 50%;
          width: 32px;
          height: 32px;
        }
        .expand-arrow,
        .compress-arrow {
          color: #6c7293 !important;
          font-size: 24px;
        }

        .menu-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-grow: 1;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;

          .arrow {
            margin-left: 15px;
            display: inline-block;
            flex-shrink: 0;
          }

          .collapse-arrow {
            display: none;
          }

          .expand-arrow {
            display: inline-block;
          }
        }
      }

      .nav-link:hover {
        background: var(--nav-bg-color);
        border-radius: 0 1.5rem 1.5rem 0;
        color: #fff;
      }

      .sub-menu-content {
        display: none;

        .sub-menu-title {
          display: none;
        }

        .sub-menu {
          padding: 0 0 0 3.25rem;
          font-size: 16px;

          .sub-nav-item {
            font-size: 14px;
            font-weight: 400;

            .sub-nav-link {
              display: block;
              text-decoration: none;
              padding: 0.8rem 10px 0.8rem 1.188rem;
              // color: #fff;
            }

            .sub-nav-link:hover {
              color: #8fbd56;
            }
          }

          .sub-nav-item .sub-nav-link.active {
            color: #8fbd56;
          }
        }
      }

      .show {
        display: block;
      }
    }

    .nav-item:last-child {
      margin-top: 16px;
      border-top: 1px solid var(--border-color);
      padding-top: 8px;
    }

    .compress-menu {
      padding-left: 24px;
      padding-top: 10px;
      border-top: 2px solid var(--border-color);

      // .arrow-container {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   width: 20px;
      //   height: 20px;
      // }

      // .compress-tool {
      //   visibility: hidden;
      //   position: relative;
      //   color: rgb(86, 86, 86);
      //   box-shadow: 0px 0px 3px 1px rgba(207, 207, 207, 0.5);
      //   background-color: #fff;
      //   border-radius: 5px;
      //   font-size: 14px;
      //   padding: 5px 10px;
      //   z-index: 1;
      //   opacity: 0;
      //   transition: opacity 0.3s ease, visibility 0.3s ease;
      //   bottom: -30px;
      //   right: 10%;
      //   transform: translateX(50%);
      // }
      // &:hover .compress-tool {
      //   visibility: visible;
      //   opacity: 1;
      //   z-index: 50;
      // }
    }

    .nav-item:has(.show) #down-arrow {
      transform: rotate(180deg);
    }

    .nav-item:has(.sub-nav-link.active) .nav-link:before {
      content: "";
      width: 3px;
      height: 100%;
      background: #e22429;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
    }

    .nav-item:has(.sub-nav-link.active) .nav-link,
    .nav-item:has(.nav-link.active) .nav-link {
      border-radius: 0 1.5rem 1.5rem 0;
      background: var(--nav-bg-color);
      color: #fff;
    }

    .nav-item:has(.sub-nav-link.active) .nav-link:before,
    .nav-item:has(.nav-link.active) .nav-link:before {
      content: "";
      width: 3px;
      height: 100%;
      background: #e22429;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.sidenav-icon-only .home-container {
  #sidenav {
    width: 70px;

    .sidenav-brand-wrapper {
      width: 68px;

      .brand-logo {
        display: none;
      }

      .brand-logo-mini {
        display: block;
      }
    }

    .sidenav-mini {
      padding: 0 !important;
    }

    .navbar-nav {
      .nav-item {
        margin: 0;
        position: relative;

        .menu-title {
          position: absolute;
          left: 70px;
          z-index: 10;
          background: var(--container-color);
          padding: 1rem;
          border-radius: 0.5rem;
          display: none;
          width: 160px;
        }

        .sub-menu-content {
          position: absolute;
          left: 70px;
          z-index: 10;
          background: var(--container-color);
          padding: 0.5rem;
          border-radius: 0.5rem;
          display: none;
          width: 160px;

          .sub-menu-title {
            display: none;
            padding: 0.5rem;
          }

          .sub-nav-link {
            color: var(--text-color);
            padding-left: 0.4rem;
          }

          .sub-menu {
            padding-left: 1.8rem;
            list-style-type: disc;
            color: var(--text-color);
          }
        }
      }

      .nav-item:hover {
        .nav-link {
          color: var(--text-color);

          .menu-title {
            display: block;
            width: 160px;

            .arrow {
              display: none;
            }
          }

          .dash {
            padding: 1rem;
            border-radius: 0.5rem;
          }
        }

        .sub-menu-content {
          display: block;
          border-radius: 0 0 0.5rem 0.5rem;

          .sub-menu-title {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  #sidenav {
    position: fixed;
    right: -211px;
    top: 70px;
    z-index: 10000000;
    transition: right 0.25s ease;
    bottom: 0;
    overflow-y: scroll;
    scrollbar-width: thin;

    .navbar-nav {
      padding-top: 0;
    }
  }

  #sidenav.right-nav {
    right: 0;
    transition: right 0.25s ease;
  }
}
