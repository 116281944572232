.download-app-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  border-radius: 20px;
  gap: 30px;
  width: 60%;
  margin-top: 50px;

  .playStore-button {
    position: absolute;
    top: -10px;
    right: -20px;
    width: 30px;
    height: 30px;
    background-color: #ff4d4f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    svg {
      font-size: 18px;
      color: #fff;
    }

    &:hover {
      background-color: #d9363e;
    }
  }

  .rs-modal-body {
    display: flex;
    flex-direction: row;
    height: auto !important;
    max-height: 550px !important;
    margin-top: 0;
    padding-bottom: 0;
    gap: 50px;
    padding: 0 60px;

    .qr-footer-logos {
      display: flex;
      justify-content: center;
      width: 250px;
      height: 400px;

      img {
        width: 80%;
      }
    }

    .qr-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex: 1;
      gap: 10px;
      .qr-code-wrapper {
        position: relative;
        display: inline-block;

        &:hover .playStore-hover-text {
          opacity: 1;
          visibility: visible;
          cursor: pointer;
        }
      }

      .playStore-hover-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 5px 10px;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }

      .dashed-or {
        display: flex;
        align-items: center;
        text-align: center;
        width: 70%;
        margin: 10px 0;

        &:before,
        &:after {
          content: "";
          flex: 1;
          border-bottom: 2px solid #dcdcdc;
        }

        &:before {
          margin-right: 10px;
        }

        &:after {
          margin-left: 10px;
        }

        span {
          font-weight: bold;
          color: #333;
        }
      }
      .google-icon {
        img {
          margin-top: -30px;
          width: 55%;
        }
      }
    }
  }
}
